import { Component, OnInit } from '@angular/core';
import { PostsService } from 'src/app/services/posts.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  public html: string;
  private subscription: Subscription;

  constructor(private translate: TranslateService, private postsService: PostsService) {
    this.translate.onLangChange.subscribe(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.loadHtml();
    });
  }

  ngOnInit(): void {
    this.loadHtml();
  }

  private loadHtml() {
    this.postsService.getPostHtml('terms-of-use').subscribe(
      data => this.html = data
    )
  }
}
