import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Post } from '../models/post.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

  public getPosts(): Observable<Post[]> {
    let lang = this.translate.currentLang;

    return this.http.get<Post[]>(`/assets/posts/${lang}/posts.json`);
  }

  public getPost(id: string): Promise<Post> {
    return new Promise<Post>((resolve) => {
      this.getPosts().subscribe((data) => {
        resolve(data.find(post => post.id == id));
      });
    });
  }

  public getPostHtml(id: string): Observable<string> {
    let lang = this.translate.currentLang;

    return this.http.get(`/assets/posts/${lang}/${id}.html`, { responseType: 'text' });
  }
}
