<section id="top" class="container py-5">
    <div class="row">
        <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
            <!--<h1>Diamonds</h1>-->
          <div class="text-center mb-5">
            <img src="/assets/images/diamond-g.png" title="Diamond" alt="Diamond" style="max-width: 70px; width: 100%"/>
          </div>
            <p>צבירת יהלומים תאפשר לך להמיר אותם להנחות והטבות אצל אנשי מקצוע שלנו וגם בחנות המוצרים האינטרנטי שלנו</p>
          <div class="text-center">
            <img src="/assets/images/coming-soon.png" title="Coming soon" alt="Coming soon" style="max-width: 200px; width: 100%"/>
          </div>
        </div>
        <!--<div class="col-12 col-md-6 col-lg-4"></div>-->
    </div>
</section>



