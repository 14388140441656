import { Component, OnInit } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  faAngleDown = faAngleDown;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('NinePlus.Life');
    this.metaService.updateTag({ name: 'description', content: 'Mental and physical support for pregnant women by a doula that will be there for you in our mobile app chat.' });
  }

  public planClicked(plan: string): void {
    this.router.navigate([`/plans/${plan}`]);
  }
}
