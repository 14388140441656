<section class="main__section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-justify">
                <h1 class="jura-text text-center mt-5">תקנון הגרלה</h1>
                <div>
                  <p><b> 1. הגדרות</b>

                  </p>
                  <p>
                  "המבצע" – כ-50 הגרלות שיערכו על בסיס שבועי  אשר יתקיימו בין לקוחותיה של עורכת ההגרלה אשר ירכשו את חבילת ההתנסות של החברה ל3 חודשים (בעלות 30 שח)  ו/או את החבילה בתשלום ("חבילת החברה"), בהתאם להוראות ההיתר הכללי לעריכת הגרלות לפרסומת מסחרית לפי חוק העונשין, התשל"ז – 1977 ("ההיתר הכללי"). מובהר כי עורכת ההגרלה תערוך את ההגרלה בהתאם להוראות תקנון זה וההיתר הכללי, פעם אחת בכל שבוע קלנדרי במהלך כל תקופת המבצע עבור רוכשים בשבוע הקלדנרי הקודם.
                  </p>
                  <p>
                  "תקופת המבצע" – בין התאריכים 13.07.2023  ועד 12.11.2023
                  </p>
                  <p>בכפוף לתנאים המפורטים בתקנון זה. עורכת ההגרלה שומרת לעצמה את הזכות לשנות ו/או להאריך ו/או לקצר את תקופת המבצע ו/או לבטלו על פי שיקול דעתה הבלעדי ולא תשמע כל טענה לעניין זה.

                  </p>
                  <p>"עורכת ההגרלה" – חברת מאמא סופורט בע"מ ח.פ. 516795507

                  "המפקח" – עו"ד יואל קינן מרחוב יגאל אלון 65 תל אביב מיקוד </p>
                  <p>6744316

                  </p>
                  <p>"משתתף" – כל אדם מעל גיל 18 אשר פעל בהתאם להוראות ולהנחיות כאמור בתקנון זה, אשר רכש את חבילת ההתנסות ל3 חודשים של החברה.  חברות ו/או קבוצות ו/או התארגנויות ו/או כל גוף שאיננו אדם פרטי אינם רשאים להשתתף במבצע זה.

                  </p>
                  <p>"הפרס" – עגלת תינוק מתוצרת חברת דונה (דגם פלוס)
                  מימון הפרס הינו על ידי עורכת ההגרלה והענקת הפרס הינו באחריות עורכת ההגרלה.</p>
                  <p>
                  </p>
                  <p>מובהר בזה כי לא תישמע כל טענה לעניין טיב הפרס כאמור לעיל וכי עורכת ההגרלה זכאית להחליפו ו/או לשנותו לפרס בעל שווי כספי או בטיב דומה לשיקול דעתה.

                  "זוכה" – משתתף ששמו עלה בהגרלה/</p>
                  <p>

                  </p>
                  <p><b>2.	"פרשנות"</b>
                  </p>
                  <p>2.1	בכל מקרה של סתירה ו/או אי התאמה כלשהי בין הוראות תקנון זה לפרסומים אחרים כלשהם בדבר המבצע, לרבות בכל מדיום אחר, תגברנה הוראות תקנון זה לכל דבר ועניין (אלא אם נאמר מפורשות אחרת באותו פרסום).
                  </p>
                  <p>2.2	בתקנון זה השימוש הינו בלשון זכר לצרכי נוחות בלבד, אך הוא מכוון לנשים וגברים כאחד באופן שווה.
                  </p>
                  <p>2.3	כותרות הסעיפים נועדו לנוחות בלבד ולא ייעשה בהם שימוש לפרשנות האמור בתקנון זה.
                  </p>
                  <p>
                  <b>3.	השתתפות בהגרלה</b>
                  </p>
                  <p>לכל משתתף כהגדרתו לעיל תוקנה הזכות להשתתף בהגרלה אחת, בתאריך הקרוב ביותר לאחר מועד רכישת השתתפות בהגרלה מיועדת לכל משתתף כהגדרתו לעיל.
                  </p>
                  <p>
                  <b>4.	שיטת ההגרלה</b>
                  </p>
                  <p>
                  4.1	כל משתתף יהיה זכאי אוטומטית להשתתף בהגרלה הקרובה שלאחר רישומו בלבד.
                  </p>
                  <p>4.2	בכפוף לתנאי תקנון זה ולמסירת פרטיו המלאים של המשתתף כאמור לעיל, ייכנס המשתתף להגרלה.
                  </p>
                  <p>4.3	מובהר כי כל משתתף יכול לזכות בפרס אחד בלבד במשך כל תקופת המבצע.
                  </p>
                  <p>4.4	בהשתתפותו בהגרלה מצהיר המשתתף כי קיבל על עצמו את התקנון וכל תנאיו ומביע את הסכמתו לרישום פרטיו לצורך קבלת דיוור של חומרים פרסומיים ועדכונים מעורכת ההגרלה.
                  </p>
                  <p>

                  <b>5.	אופן עריכת ההגרלה</b>
                  </p>
                  <p>5.1	ההגרלות יבוצעו באמצעות מערכת Zoom
                  </p>
                  <p>5.2	המפקח יהא אחראי לפקח כי לא נפל פגם או תרמית בעריכת ההגרלות בלבד.
                  </p>
                  <p>5.3	תוצאות כל הגרלה תהיינה סופיות ומוחלטות, ולא תישמע כל טענה בעניין זה.
                  </p>
                  <p>5.4	במסגרת המבצע, ועל פי הוראות תקנון זה, יהיה ניתן להשתתף במבצע ולזכות בפרס ההגרלה הכל כמפורט בתקנון זה.
                  </p>
                  <p>5.5	תשומת ליבו של המשתתף מופנית לכך כי ייתכן ובמהלך המבצע יעודכן התקנון. התקנון המעודכן יהיה זמין במשרדי החברה, במשרדי המפקח ובאתר החברה , בכל עת , לשימושו של המשתתף.
                  </p>
                  <p>5.6	הוראות תקנון זה, ככל שהן נוגעות להיבטי ההגרלות של המבצע, כפופות להוראות ההיתר הכללי
                  </p>
                  <p>
                 <b> 6.	קבלת הפרס</b>
                  </p>
                  <p>6.1	נציגי עורכת ההגרלה יצרו קשר עם המשתתף הזוכה בכל אחת מההגרלות על פי הפרטים האישיים שמסר המשתתף במועד רישומו לחבילת החברה.
                  </p>
                  <p>6.2	כל משתתף זוכה יקבל את הפרס לביתו בתוך 14 ימי עסקים.
                  </p>
                  <p>6.3	עורכת ההגרלה שומרת לעצמה את הזכות שלא להעניק פרס למשתתף שזכה, היה והתברר כי אותו משתתף נהג במרמה ו/או הזין פרטים אישיים לא נכונים ו/או לא מלאים ו/או נהג בניגוד להוראות תקנון זה, ולא יהיו לו כל טענה תביעה או דרישה כנגד עורכת הגרלה בקשר עם האמור.
                  </p>
                  <p>6.4	הפרס לא ניתן להמרה כלשהיא.
                  </p>
                  <p>6.5	הזכייה בפרס הינה אישית ואינה ניתנת להעברה, האחריות לשמירה ומימוש של הפרס תהא על המשתתף הזוכה בלבד.
                  </p>
                  <p>6.6	עורכת ההגרלה אינה נושאת באחריות מכל סוג ומין שהוא לטיב ו/או לאיכות הפרס במסגרת הגרלה זאת, למועד אספקתו או לכל נזק ישיר ו/ או עקיף אשר ייגרם למשתתף ו/או לצד שלישי כתוצאה משימוש בו ומימושו.
                  </p>
                  <p><b>                  7.	מפקח</b>
                  </p>
                  <p>
                  המפקח יהיה רשאי לטפל בפניות המשתתפים, להכריע בכל מחלקת בינם לבין עורכת ההגרלה, אי הבנה, ספק או כל דבר אחר ובכלל פרשנות התקנון.
                  </p>
                  <p>
                 <b> 8.	כללי</b>
                  </p>
                  <p>
                  8.1	כל מס ותשלום חובה, מכל סוג שהוא, על פי כל דין, ככל שחל, יחול על הזוכה וישולם על ידו. עורכת ההגרלה לא תהיה חייבת בתשלום מס כלשהו בקשר לזכייה.
                  </p>
                  <p>8.2	ההשתתפות בהגרלה הינה באחריותו הבלעדית של המשתתף. עורכת ההגרלה וכל מי מטעמה לא יישאו בכל אחריות לכל נזק ו/או הפסד ו/או הוצאה שייגרמו למי מהמשתתפים הקשורים במישרין ו/או בעקיפין להגרלה זאת.
                  </p>
                  <p>8.3	כל זכויות הקניין הרוחני בהגרלה, לרבות פטנטים, זכויות יוצרים, סימני מסחר וסודות מסחריים ככל שישנם, הינם רכושה הבלעדי של עורכת ההגרלה או של צדדים שלישיים שעורכת ההגרלה קיבלה מהם רישיון שימוש כדין.
                  </p>
                  <p>8.4	למשתתפים ולכל מי מטעמם לא תהיה דרישה ו/או כל תביעה ו/או כל טענה מכל סוג ומין בקשר להגרלה, לפרס, לתקנון ו/או לכל עניין אחר הקשור והכרוך בהם.
                  </p>
                  <p>8.5	עם השתתפותו בהגרלה מסכים הזוכה כי לעורכת ההגרלה שמורה הזכות לפרסם ולסקר את עובדת זכייתו של כל זוכה באתר עורכת ההגרלה או ברשתות החברתיות שלה.
                  </p>
                  <p>8.6	עורכת ההגרלה תהא רשאית על פי שיקול דעתה הבלעדי לבטל את ההגרלה, לשנות את הפרס בכל שלב משלבי ההגרלה, לשנות את כללי ההגרלה , לשנות את אופן בחירת הזוכים ולשנות תקנון זה, ולא תהיה למשתתפים כל זכות או טענה בנוגע לכך. שינוי תנאי התקנון יפורסם באתר עורכת ההגרלה או במהלך הכנס.
                  </p>
                  <p>8.7	המפקח, עורכת ההגרלה, מנהליהם, שותפיהם, עובדיהם ובני משפחותיהם אינם רשאים להשתתף בהגרלה. "בני משפחה" לעניין סעיף זה: בן זוג, ילדים, אחים, אחיות.
                  </p>
                  <p>8.8	עותק מן התקנון ניתן יהיה לקבל במשרדי עורכת ההגרלה בשעות הפעילות הרגילות או בעת הרישום להגרלה.
                  </p>
                  <p>8.9	הדין החל על הסכם זה וכל הנובע ממנו הוא הדין הישראלי בלבד. סמכות השיפוט הייחודית נתונה לבתי משפט המוסמכים במחוז מרכז.

                  </p>
                  <p></p>
                  <p></p>
                </div>
            </div>

        </div>
    </div>
</section>
