import { Component, OnInit, Input } from '@angular/core';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-try-us',
  templateUrl: './try-us.component.html',
  styleUrls: ['./try-us.component.scss']
})
export class TryUsComponent implements OnInit {

  public faAngleLeft = faAngleLeft;
  public faAngleRight = faAngleRight;
  @Input() isMain: boolean = false;
  @Input() linksOnly: boolean = false;
  @Input() textOnly: boolean = false;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }
}
