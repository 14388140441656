<!--
<a *ngIf="!linksOnly" class="action-btn in-main" routerLink="/plans/trial">
  <fa-icon *ngIf="translate.currentLang == 'he'" [icon]="faAngleLeft"></fa-icon>
  <fa-icon *ngIf="translate.currentLang != 'he'" [icon]="faAngleRight"></fa-icon>
  <span>{{ 'StartFree' | translate}}</span>
</a>-->


<div class="try-us-container" style="margin-top: 20px"><!-- *ngIf="!textOnly"-->
  <!-- href="https://private.invoice4u.co.il/newsite/he/clearing/public/i4u-clearing?ProductGuid=ecade4b5-bd40-4bfd-897b-840013175796&utm_source=homewebsite&utm_medium=direct&utm_campaign=seo&utm_content=fromwebsite" -->
  <a class="btn btn-success" target="_blank" href="https://lp.nineplus.life/freetips/">
    {{ 'Register.Form.Submit' | translate}}
  </a>
  <!--
  <a href="https://play.google.com/store/apps/details?id=com.nine.plus.mobile"
    style="display:inline-block">
    <img class="android" alt='Get it on Google Play' src='/assets/images/google-play-icon.png' />
    </a>
  <a href="https://apps.apple.com/pl/app/nine-plus-life/id1353400109">
    <img class="android" alt='Download on the App Store' src='/assets/images/app-store-icon.png' />
  </a>
  -->
</div>

