import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trial-plan',
  templateUrl: './trial-plan.component.html',
  styleUrls: ['./trial-plan.component.scss']
})
export class TrialPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
