import { Component } from '@angular/core';

@Component({
  selector: 'app-full-plan',
  templateUrl: './full-plan.component.html',
  styleUrls: ['./full-plan.component.scss']
})
export class FullPlanComponent {

  public subscriptionPlanId = 2;

}
