<section id="top" class="container py-5">
    <div class="row">
        <div class="col-12">
          <div class="banner-wrap">
            <img src="/assets/images/basic-plan-banner.png" class="img-fluid"/>
            <!--
            <a class="btn" target="_blank" href="https://private.invoice4u.co.il/newsite/he/clearing/public/i4u-clearing?ProductGuid=ecade4b5-bd40-4bfd-897b-840013175796&utm_source=homewebsite&utm_medium=direct&utm_campaign=seo&utm_content=fromwebsite">
              7 ימי ניסיון ללא התחייבות
            </a>-->
          </div>
        </div>
    </div>
</section>
