<ngb-carousel class="reviews-container">
    <ng-template ngbSlide>
        <div class="row">
            <div class="col-sm-4">
                <!--<img src="https://www.clalbit.co.il/Content/Images/logo3.png">-->
              <img src="assets/images/clal.png">
            </div>
            <div class="col-sm-4">
                <img
                    src="https://static.wixstatic.com/media/3b81c0_91b46343318e424abda1acf98de2f3e3~mv2.png/v1/crop/x_296,y_614,w_2007,h_1206/fill/w_220,h_133,al_c,q_85,usm_0.66_1.00_0.01/Artboard%2011%20copy%404x.webp">
            </div>
            <div class="col-sm-4">
                <img src="https://upload.wikimedia.org/wikipedia/he/thumb/3/3c/WolfsonHospital.svg/320px-WolfsonHospital.svg.png">
            </div>
        </div>
    </ng-template>
</ngb-carousel>
