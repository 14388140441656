<section id="top">
  <div class="container h-100 text-center">
    <div class="row">
      <div class="col-sm-6">
        <div id="top-image"></div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-12">
            <h1>{{ 'Homepage.Top.Title' | translate }}</h1>
            <p *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7]">{{ 'Homepage.Top.Subtitle.' + i | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4 feature" *ngFor="let i of [0, 1, 2]">
            <span>{{'Homepage.Top.Features.' + i | translate}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>{{ 'Homepage.Top.Text' | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-try-us [isMain]="true"  [textOnly]="true"></app-try-us>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="what-is-doula">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="title-container">
          <h2>{{ 'Homepage.WhatIsDoula.Title' | translate}}</h2>
        </div>
      </div>
      <div class="col-md-6 my-auto">
        <div class="mx-auto">
          <h2>{{ 'Homepage.WhatIsDoula.Subtitle' | translate}}</h2>
          <p *ngFor="let i of [0, 1]">{{ 'Homepage.WhatIsDoula.Text.' + i | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="what" [class.flipped]="translate.currentLang != 'he'">
  <div class="container">
    <div class="row">
      <div class="col-7">
        <div id="what-text-container">
          <h2>{{ 'Homepage.What.Title' | translate}}</h2>
          <p *ngFor="let i of [0]">{{ 'Homepage.What.Text.' + i | translate}}</p>
        </div>
      </div>
      <div class="col-5"></div>
    </div>
  </div>
</section>

<section id="how">
  <div id="how-bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div id="app-example-image"></div>
      </div>
      <div class="col-md-9">
        <h2>{{ 'Homepage.How.Title' | translate}}</h2>
        <p *ngFor="let i of [0]">{{ 'Homepage.How.Text.' + i | translate}}</p>
        <div class="row">
          <div class="col-md-6 steps-col" *ngFor="let i of [0, 1]">
            <div class="row align-items-center" *ngFor="let i of [i * 2, i * 2 + 1]">
              <ng-container *ngIf="i < 3">
                <div class="col-3">
                  <span class="feature-number rounded-circle text-center">{{ i + 1 }}</span>
                </div>
                <div class="col-9">
                  <span class="feature-text">{{ 'Homepage.How.Features.' + i | translate}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="how-try-us-container" class="row align-items-center">
      <div class="col-12 text-center">
        <app-try-us [textOnly]="true"></app-try-us>
      </div>
    </div>
  </div>
</section>

<section id="why">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div id="why-image"></div>
      </div>
      <div class="col-md-6">
        <div class="mx-auto">
          <h2>{{ 'Homepage.Why.Title' | translate}}</h2>
          <p *ngFor="let i of [0, 1]">{{ 'Homepage.Why.Text.' + i | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="plans">
  <div class="container align-items-center" style="max-width: 75em;">
    <div class="row text-center pb-5">
      <div class="col-12">
        <h2>{{ 'Plans.Title' | translate }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4 offset-md-2">
        <app-plan [planId]="'trial'" [checkList]="[0]"></app-plan>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4">
        <app-plan [planId]="'basic'" [checkList]="[0,1]" [isBestPlan]="true"></app-plan>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 offset-md-2">
        <app-plan [planId]="'video'" [checkList]="[0]"></app-plan>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4">
        <app-plan [planId]="'full'" [checkList]="[0,1,2]"></app-plan>
      </div>
    </div>
    <div class="row align-items-center mt-5">
      <div class="col-12 text-center">
        <app-try-us [textOnly]="true"></app-try-us>
      </div>
    </div>
  </div>
</section>
<section id="reviews">
  <div class="container" style="padding-top: 1rem">
    <div class="row">
      <div class="col-md-4">
        <div id="reviews-image"></div>
      </div>
      <div class="col-md-8 content-container">
        <h2>{{ 'Reviews.Title' | translate}}</h2>
        <div class="reviews-container">
          <ngb-carousel [showNavigationIndicators]="false" [interval]="0">
            <ng-template ngbSlide *ngFor="let i of [0, 1, 2]">
              <p>{{ 'Reviews.Content.' + i + '.Text' | translate}}</p>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="faq">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>{{ 'Faq.Title' | translate }}</h2>
        <ngb-accordion>
          <ngb-panel *ngFor="let i of [0, 1, 2, 3, 4]">
            <ng-template ngbPanelTitle>
              <div class="btn-content">
                <span>{{'Faq.Content.' + i + '.Question' | translate}}</span>
                <fa-icon [icon]="faAngleDown"></fa-icon>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{'Faq.Content.' + i + '.Answer' | translate}}</ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</section>
<section id="partners">
  <div class="fluid-container">
    <app-partners></app-partners>
  </div>
</section>
<section id="secured">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 my-auto">
        <h2>{{ 'Secured.Question' | translate}}</h2>
      </div>
      <div class="col-lg-2 my-auto">
        <img src="assets/images/hipaa-aud-icon.png" style="width: 10em" alt="HIPAA Audited">
      </div>
      <div class="col-lg-6 my-auto">
        <p>{{ 'Secured.Answer' | translate}}</p>
      </div>
    </div>
  </div>
</section>

