<section id="top" class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1>
              {{ 'Plans.Full.Title' | translate}}
            </h1>
            <p class="price">
                <!--{{ 'Plans.Full.Price.0' | translate }}-->
                <!--{{ 'Plans.Full.Price.1' | translate | lowercase }}-->
            </p>
          <!--<p>{{ 'Plans.Full.Page.Text' | translate}}</p>-->
          <!--<p>{{ 'Plans.Full.Page.Remarks.0' | translate}}</p>-->
            <!--<div id="image" style="background-image: url(/assets/images/plan-support.png);"></div>-->
            <p>
              <img src="/assets/images/coming-soon.png" title="Coming Soon" alt="Coming Soon" style="max-width: 300px; width: 100%"/>
            </p>
        </div>
    </div>
</section>
<!--
<section class="container pb-5">
    <div class="row flex-row-reverse">
        <div class="col-md-6 pb-5 pb-md-0">
            <app-already-user [subscriptionPlanId]="subscriptionPlanId"></app-already-user>
        </div>
        <div class="col-md-6">
            <app-registration-form [subscriptionPlanId]="subscriptionPlanId"></app-registration-form>
        </div>
    </div>
</section>-->

<!--
<section class="container pb-5">
  <div class="row flex-row-reverse">
    <div class="col-md-6">
      <div id="image-bg">
        <div style="background-image: url(/assets/images/plan-support.png);"></div>
      </div>
    </div>
    <div class="col-md-6">
      <app-registration-form [subscriptionPlanId]="subscriptionPlanId"></app-registration-form>
    </div>
  </div>
</section>
-->
