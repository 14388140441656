import { Component, Input, OnInit } from '@angular/core';
import { Pay } from 'src/app/models/pay.model';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-already-user',
  templateUrl: './already-user.component.html',
  styleUrls: ['./already-user.component.scss']
})
export class AlreadyUserComponent implements OnInit {

  @Input()
  public subscriptionPlanId: number;
  public payModel = new Pay('');

  constructor(
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
  }

  pay() {
    this.paymentService.pay(this.subscriptionPlanId);
  }
}
