<div id="already-user-form">
    <div>
        <h3>{{ 'Plans.AlreadyUser.Title' | translate }}</h3>
        <p>{{ 'Plans.AlreadyUser.Text' | translate }}</p>
        <form (ngSubmit)="pay()" #payForm="ngForm">
            <button type="submit" class="btn btn-primary" [disabled]="!payForm.form.valid">
                {{ 'Plans.AlreadyUser.Submit' | translate}}
            </button>
        </form>
    </div>
</div>