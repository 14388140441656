<div class="btn-wrap">
  <a class="btn btn-success" target="_blank" href="https://private.invoice4u.co.il/newsite/he/clearing/public/i4u-clearing?ProductGuid=ecade4b5-bd40-4bfd-897b-840013175796&utm_source=homewebsite&utm_medium=direct&utm_campaign=seo&utm_content=fromwebsite">
    {{ 'Register.Form.Submit' | translate}}
  </a>
</div>
<!--
<h3>{{ 'Register.Title' | translate }}</h3>
<form (ngSubmit)="submit()" #registrationForm="ngForm">
    <div class="form-group">
        <label for="email">
            {{ 'Register.Form.Email' | translate}}
        </label>
        <input [(ngModel)]="model.email" type="email" class="form-control" id="email" name="email" #email="ngModel"
            required email
            [class.border-danger]="email.invalid && (email.dirty || email.touched)">
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
            {{ 'Register.Form.Errors.InvalidEmail' | translate}}
        </div>
    </div>
    <div class="form-group">
        <label for="phone">
            {{ 'Register.Form.Phone' | translate}}
        </label>
        <input [(ngModel)]="model.phone" type="tel" class="form-control" id="phone" name="phone" #phone="ngModel"
            required pattern="^\+?(\d{9,12})$"
            [class.border-danger]="phone.invalid && (phone.dirty || phone.touched)">
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-danger">
            {{ 'Register.Form.Errors.InvalidPhone' | translate}}
        </div>
    </div>
    <div class="form-group">
        <label for="firstName">
            {{ 'Register.Form.FirstName' | translate}}
        </label>
        <input [(ngModel)]="model.firstName" type="text" class="form-control" id="firstName" name="firstName"
            #firstName="ngModel" pattern="^[a-zA-Z ]+$" required
            [class.border-danger]="firstName.invalid && (firstName.dirty || firstName.touched)">
        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
            {{ 'Register.Form.Errors.InvalidFirstName' | translate}}
        </div>
    </div>
    <div class="form-group">
        <label for="lastName">
            {{ 'Register.Form.LastName' | translate}}
        </label>
        <input [(ngModel)]="model.lastName" type="text" class="form-control" id="lastName" name="lastName"
            #lastName="ngModel" pattern="^[a-zA-Z ]+$" required
            [class.border-danger]="lastName.invalid && (lastName.dirty || lastName.touched)">
        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
            {{ 'Register.Form.Errors.InvalidLastName' | translate}}
        </div>
    </div>
    <div class="form-group">
        <label for="pregnancyWeek">
            {{ 'Register.Form.PregnancyWeek' | translate}}
        </label>
        <input [(ngModel)]="model.pregnancyWeek" type="number" class="form-control" id="pregnancyWeek"
            name="pregnancyWeek" #pregnancyWeek="ngModel" required maxlength="2" (change)="fixPregnancyWeek()"
            [class.border-danger]="pregnancyWeek.invalid && (pregnancyWeek.dirty || pregnancyWeek.touched)">
        <div *ngIf="pregnancyWeek.invalid && (pregnancyWeek.dirty || pregnancyWeek.touched)" class="text-danger">
            {{ 'Register.Form.Errors.InvalidPregnancyWeek' | translate}}
        </div>
    </div>
    <div>
        <button type="submit" class="btn btn-primary" [disabled]="isLoading || !registrationForm.form.valid">
            {{ 'Register.Form.Submit' | translate}}
        </button>
        <span *ngIf="error" class="text-danger">
            {{ error | translate }}
        </span>
    </div>
</form>
-->
