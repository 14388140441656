<section id="top" class="container py-5">
    <div class="row flex-row">
        <div class="col-12 col-md-8 offset-md-2">
            <h1 class="text-center">{{ 'ContactUs.title' | translate}}</h1>

        </div>

        <div class="col-12 col-md-8 offset-md-2">
          <div *ngIf="isSuccessResponse" class="text-center text-success">
            <h3>
              <i class="fa fa-check"></i>
              {{ 'ContactUs.ThanYou' | translate}}!
            </h3>
            <h4>{{ 'ContactUs.WeWillContactYouShortly' | translate}}</h4>
          </div>

          <form [formGroup]="contactUsForm" #formDirective="ngForm" *ngIf="!isSuccessResponse"
                (ngSubmit)="onSubmitForm(formDirective)">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ 'ContactUs.Name' | translate}}<sup>*</sup></label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [ngClass]="{'is-invalid':!isValidFormField('name')}" name="name"
                       formControlName="name"><!-- placeholder="Enter your name"-->
                <div class="invalid-feedback" [style.display]="isValidFormField('name')?'none':'block'">
                  Enter valid Name
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ 'ContactUs.Phone' | translate}}<sup>*</sup></label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [ngClass]="{'is-invalid':!isValidFormField('phone')}" name="phone"
                       formControlName="phone"><!-- placeholder="Enter your Phone" -->
                <div class="invalid-feedback" [style.display]="isValidFormField('phone')?'none':'block'">
                  Enter valid Phone
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ 'ContactUs.Email' | translate}}<sup>*</sup></label>
              <div class="col-sm-10">
                <input type="email" class="form-control" [ngClass]="{'is-invalid':!isValidFormField('email')}"
                       name="email" formControlName="email"><!-- placeholder="Enter your Email" -->
                <div class="invalid-feedback" [style.display]="isValidFormField('email')?'none':'block'">
                  Enter valid Email
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ 'ContactUs.Category' | translate}}<sup>*</sup></label>
              <div class="col-sm-10">
                <select class="form-control" [ngClass]="{'is-invalid':!isValidFormField('category')}"
                       name="category" formControlName="category">
                  <option value="">- {{ 'ContactUs.SelectCategory' | translate}} -</option>
                  <option *ngFor="let category of categories" [value]="category">{{ ('ContactUs.' + category) | translate}}</option>
                </select>
                <div class="invalid-feedback" [style.display]="isValidFormField('category')?'none':'block'">
                  {{ 'ContactUs.SelectCategory' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ 'ContactUs.Message' | translate}}<sup>*</sup></label>
              <div class="col-sm-10">
                <textarea class="form-control" [ngClass]="{'is-invalid':!isValidFormField('message')}"
                          formControlName="message" rows="5" name="message"></textarea><!--  placeholder="Enter your Message" -->
                <div class="invalid-feedback" [style.display]="isValidFormField('message')?'none':'block'">
                  Enter valid Message
                </div>
              </div>
            </div>
            <div class="text-center">
              <button type="submit" [disabled]="contactUsForm.pristine || isRequestProcessing"
                      class="btn btn-primary lg-btn" name="submitButton"
                      [ngClass]="{'submit__loader':isRequestProcessing}">{{ 'ContactUs.Send' | translate}}</button>
            </div>
          </form>
        </div>
    </div>
</section>



