<section class="container">
    <div class="row">
        <div class="col-12">
            <h1>{{ 'Payments.Fail.Title' | translate }}</h1>
            <p>
                {{ 'Payments.Fail.Text.0' | translate }}
                <a href="mailto:support@nineplus.life" target="_blank" rel="noopener noreferrer">{{ 'Payments.Fail.Text.1' | translate }}</a>
            </p>
        </div>
    </div>
</section>