import {Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SendMailService} from 'src/app//services/send-mail.service';
import {ContactUsModel} from 'src/app/models/contact-us.model';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  private _routeParamsSubscription: any = null;
  /* modalRef: BsModalRef;

  config = {
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-lg submit__message-body'
  };*/

  contactUsForm: FormGroup;

  isSuccessResponse = false;
  isRequestProcessing = false;

  constructor(
              private sendMailService: SendMailService,
              private fb: FormBuilder,
              // private modalService: BsModalService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private elemenet: ElementRef) {

  }

  public categories: string[] = [
    'I want to hear more',
    'I have problem with Mobile App',
    'Remove my personal data',
    'Registration',
    'Other'
  ];

  ngOnInit() {
    this.createForm();

    this._routeParamsSubscription = this.route.queryParams.subscribe(params => {
      console.log('params', params);
      if (params['category']) {
        switch (params['category']) {
          case 'more':
            this.contactUsForm.controls['category'].markAsDirty();
            this.contactUsForm.controls['category'].setValue(this.categories[0]);
            break;
          case 'mobile-app':
            this.contactUsForm.controls['category'].markAsDirty();
            this.contactUsForm.controls['category'].setValue(this.categories[1]);
            break;
          case 'remove-personal-data':
            this.contactUsForm.controls['category'].markAsDirty();
            this.contactUsForm.controls['category'].setValue(this.categories[2]);
            break;
          case 'register':
            this.contactUsForm.controls['category'].markAsDirty();
            this.contactUsForm.controls['category'].setValue(this.categories[3]);
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }

  private createForm() {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      category: ['', [Validators.required, Validators.min(0)]],
      message: ['', [Validators.required, Validators.min(5)]],
      //recaptcha: [null, [Validators.required]],
      //issueTypeId: 0
    });
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  isValidFormField(name: string) {
    return !(this.contactUsForm.get(name).invalid && this.contactUsForm.get(name).dirty);
  }

  onSubmitForm(/*successModal: TemplateRef<any>, errorModal: TemplateRef<any>,*/ formDirective: FormGroupDirective) {
    this.isRequestProcessing = true;
    console.log('onSubmitForm', this.contactUsForm);
    if (this.contactUsForm.valid) {

      this.sendMailService.sendContactUsMessage(this.contactUsForm.value)// sendContactUsMessage
        .subscribe(
          (data: ContactUsModel) => {
            console.log(data);
            formDirective.resetForm();
            this.contactUsForm.reset();
            this.isSuccessResponse = true;
            this.isRequestProcessing = false;
          },
          error => {
            console.log(error);

            if(error.status == 200){
              formDirective.resetForm();
              this.contactUsForm.reset();
              this.isSuccessResponse = true;
              this.isRequestProcessing = false;
              return;
            }
            this.isSuccessResponse = false;
            this.isRequestProcessing = false;
            alert('Sorry... Something went wrong!');
          }
        );
    } else {
      this.validateAllFormFields(this.contactUsForm);
      this.isRequestProcessing = false;
    }
  }
}

