<section id="top" class="container py-5">
    <div class="row flex-row">
        <div class="col-12 col-md-8 offset-md-2 mt-4">
            <h1 class="text-center">{{ 'Questionnaire.title' | translate}}</h1>
        </div>

        <div class="col-12 col-md-8 offset-md-2 mt-4">
          <form *ngIf="!success"
            class="form"
            role="form"
            name="form"
            novalidate
            (submit)="form.valid && onSubmitForm()"
            #form="ngForm"
            [ngClass]="{ 'ng-submitted': form.submitted }"
          >

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.FullName' | translate}}<sup style="color: red">*</sup></label>
              <input  [disabled]="!token"
                      name="name"
                      type="text"
                      [(ngModel)]="model.FullName"
                      placeholder=""
                      min="9"
                      max="42"
                      #fullName="ngModel"
                      class="form-control"
                      required
              />
              <div class="error">
                <div *ngIf="(form.submitted || fullName.dirty) && fullName.invalid">
                  Enter valid name
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.Residence' | translate}}<sup style="color: red">*</sup></label>
              <input  [disabled]="!token"
                      name="Residence"
                      type="text"
                      [(ngModel)]="model.Residence"
                      placeholder=""
                      min="9"
                      max="42"
                      #residence="ngModel"
                      class="form-control"
                      required
              />
              <div class="error">
                <div *ngIf="(form.submitted || residence.dirty) && residence.invalid">
                  Enter valid residence
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.Age' | translate}}</label>
              <input  [disabled]="!token"
                name="Age"
                type="number"
                [(ngModel)]="model.Age"
                #age="ngModel"
                class="form-control"
              />
              <!--
                <option value="1">1</option>
                <option value="2">2+</option>
              </select>-->
              <!--
              <div class="error">
                <div *ngIf="(form.submitted || pregnancyNumber.dirty) && pregnancyNumber.invalid">
                   - {{ 'Questionnaire.SelectPregnancyNumber' | translate}} -
                </div>
              </div>-->
            </div>

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.PregnancyNumber' | translate}}</label>
              <input  [disabled]="!token"
                      name="PregnancyNumber"
                      type="number"
                      [(ngModel)]="model.PregnancyNumber"
                      #pregnancyNumber="ngModel"
                      class="form-control"
                      min="0"
              />
              <div class="error">
                <div *ngIf="(form.submitted || pregnancyNumber.dirty) && pregnancyNumber.invalid">
                  Enter valid number
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.ChildrenNumber' | translate}}</label>
              <input  [disabled]="!token"
                      name="ChildrenNumber"
                      type="number"
                      [(ngModel)]="model.ChildrenNumber"
                      #childrenNumber="ngModel"
                      class="form-control"
                      min="0"
              />
              <div class="error">
                <div *ngIf="(form.submitted || childrenNumber.dirty) && childrenNumber.invalid">
                  Enter valid number
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.Week' | translate}}<sup style="color: red">*</sup></label>
              <input  [disabled]="!token"
                      name="Week"
                      type="number"
                      [(ngModel)]="model.Week"
                      placeholder="Set a week from 9 to 42"
                      min="9"
                      max="42"
                      #week="ngModel"
                      class="form-control"
                      required
              />
              <div class="error">
                <div *ngIf="(form.submitted || week.dirty) && week.invalid">
                  Enter valid week number
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="control-label">{{ 'Questionnaire.Date' | translate}}</label>
              <input  [disabled]="!token"
                      name="Date"
                      type="date"
                      [(ngModel)]="model.Date"
                      placeholder=""
                      min="9"
                      max="42"
                      #date="ngModel"
                      class="form-control"
              />
            </div>

            <div class="text-center">
              <button type="submit" [disabled]="!token || form.pristine || isRequestProcessing"
                      class="btn btn-primary lg-btn" name="submitButton"
                      [ngClass]="{'submit__loader':isRequestProcessing}">{{ 'Questionnaire.Send' | translate}}</button>
            </div>

            <div class="text-center text-danger">
              <div *ngIf="!token">Missing required token parameter</div>
              <div *ngIf="formError">{{formError}}</div>
            </div>
          </form>

          <div *ngIf="success" class="text-center text-success">
            <h3>
              <i class="fa fa-check"></i>
              {{ 'Questionnaire.ThanYouForRegistration' | translate}}!
            </h3>
            <h4>{{ 'Questionnaire.WeWillContactYouShortly' | translate}}</h4>
          </div>
        </div>
    </div>
</section>



