<section class="main__section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-justify">
                <h1 class="jura-text text-center mt-5">מדיניות פרטיות</h1>
                <p>
                מטרתה של מדיניות הפרטיות הינה להבהיר למשתמש באתר nineplus.lifeובאפליקציית Nine Plus Life המנוהל על ידי מאמא סופורט  בע"מ (להלן בהתאמה "האתר" ו "האפליקציה" ו-"החברה") את מדיניות הפרטיות הנהוגה באתר ובאפליקציה, ולפרט את אופן השימוש שיעשה על ידי החברה במידע, איסופו ושימושיו. החברה רשאית לערוך או לשנות מעת לעת את מדיניות הפרטיות, ושימושך באתר או באפליקציה, ו\או בשירותים שהם מציעים במסגרת שירותי החברה, לאחר עדכון ופרסום הצהרת פרטיות מעודכנת מהווה הסכמתך לשינוי כאמור.
                </p>
                <p>
                האתר וכן האפליקציה, אוספים מידע לגבי המשתמשים בו, מתוך מטרה לשפר את איכות וטיב השירותים שהם מעניקים. קיימים מספר סוגי מידע הנאגרים:
                </p>
              <p>
                מידע כללי  - מידע כללי על המשתמשים באתר ו/או באפליקציה על בסיס מצטבר ואנונימי על מנת לשפר ולייעל את איכות וטיב השירותים הניתנים באמצעותם באופן בו לא ניתן לשייך כל מידע מזהה ופרטים אישיים כלשהם למידע. מידע כללי יכול שיכלול כדלהלן – התנהגות, כמות וסוגי מכשירים המגיעים לאתר ולאפליקציה כדי לבצע פעולות לצפייה וביצוע פעולות, וכיו"ב.
              </p>
              <p>
                מידע אישי - מידע הנאסף על ידי החברה מנרשמי הקורסים והלקוחות המקבלים את שירותי החברה, במסגרת שימושם באתר ו/או באפליקציה, ונאגר במאגרי המידע של החברה, שהינו בעל אופי אישי, בין אם ברישום לשירות, ובין אם במהלך השימוש בהם כגון: שם, אזור מגורים, מספר טלפון, כתובת דואר אלקטרוני, וכן מידע כגון שבוע ההריון, האם הריון ראשון, השפה המועדפת, וכיוב . מידע אישי נאסף רק כאשר המשתמשים בוחרים לספק מידע אישי כאמור מרצונם החופשי.
              </p>
              <p>
                החברה נעזרת במידע האישי האמור על מנת לספק את השירותים למשתמשים ובכדי לתת מענה לצרכים עבור המשתמשים באתר. מבלי לגרוע מהאמור לעיל, בעת מסירת מידע, הנך נותן לחברה זכות שימוש בו, כאמור בתנאי הצהרת פרטיות זו.
              </p>
              <p>
                מידע מעובד – מידע אשר יאסף על ידי החברה ו/או מי מטעמה ממשתמש במסגרת ביצוע קורס ו/או שירות אחרים שיקבל מהחברה, אשר ימסר מרצונו החופשי של המשתמש. מידע כאמור הינו מידע סודי אישי ואינטימי אשר אינו בנחלת הכלל וכולל בין היתר גם עיבוד וניתוח של מידע שימסר לנו על ידיך מרצונך החופשי.
              </p>
              <p>
                מסירת מידע כלשהו אודותיך על ידיך במסגרת האתר או האפליקציה או בכל אופן אחר, מהווה הסכמה מצידך לכך שהינך מתיר לחברה להשתמש בפרטיך על מנת לספק לך את השירותים אשר ביקשת לקבל באתר ו/או באפליקציה ו\או לשפר אותם וכן לצורך קבלת דברי דואר בין בדואר רגיל ובין בדואר אלקטרוני ובין בכל דרך אחרת, לרבות הצעות בעלות תוכן שיווקי ופרסומי וכן לצורך עיבודים סטטיסטיים, פילוחי מידע ועוד. אם אינך מעוניין בדיוור כאמור, זכותך להודיע לנו כי אינך מעוניין באמצעות המייל support@nineplus.life
              </p>
              <p>
                החברה רשאית והנך מתיר לחברה, לשמור מידע שתאסוף אודותיך במאגריה, ולעשות בו שימוש, בכפוף לתנאי השימוש, מדיניות הפרטיות ועל פי הוראות כל דין.
              </p>
              <p>
                לשם פעילותם השוטפת של האתר והאפליקציה, לרבות לצורך איסופו של מידע, אימות פרטים, והתאמות להעדפותיך, האתר והאפליקציה עושים ו/או יעשה שימוש ב"עוגיות" (Cookies). עוגיות הן קבצי טקסט, אשר הדפדפן של המשתמש יוצר לפי פקודה ממחשבי החברה. חלק מהעוגיות יפקעו כאשר תסגור את הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב שלך. העוגיות מכילות מידע מגוון כדוגמת הדפים שבהם ביקרת, משך הזמן ששהית בשימוש באתר או באפליקציה, מהיכן הגעת אל האתר, מדורים ומידע שהנך מבקש לראות בעת הכניסה לאתר הרגלי הגלישה שלך,  וכיוב. המידע בעוגיות מוצפן, והחברה נוקטת צעדי זהירות כדי להבטיח שרק מחשביה או מי מטעמה יוכלו לקרוא ולהבין את המידע האגור בה. אם הנך מעוניין להימנע מהכנסת "עוגיה" מעין זו מהאתר או מהאפליקציה, עליך לשנות את ההגדרות במכשיר בו הנך עושה שימוש. יתר על כן תוכל למחוק קבצים אלו שהושתלו במחשבך בכל עת.
              </p>
              <p>
                האתר מכיל קישורים לאתרים חיצוניים, וחלקו מסתמך על מידע המגיע מצדדים שלישיים. הנך מודע לכך כי החברה אינה מתחייבת, כי המידע הינו אמין ומדויק ולא בדקה את אמינות המידע של אותם צדדים שלישיים. החברה לא תהיה אחראית לכל נזק, אובדן או חיוב מכל מין וסוג שהוא שייגרם לך כתוצאה מהסתמכות על מידע זה.
              </p>
              <p>
                החברה נוקטת באמצעי זהירות התואמים את הסטנדרטים הנהוגים בתחום על מנת לשמור, ככל האפשר, על סודיות מידע המתקבל על ידיך, ואולם, למרות האמור לעיל, קיים סיכון של חדירה למאגרי הנתונים של החברה, וכן לתעבורת הנתונים בין מחשבך למחשבי החברה. לפיכך, הנך מאשר כי החברה לא תהיה אחראית לכל אובדן נזק או תשלום שיגרם לך (אם יגרם) כתוצאה מחדירה למאגר הנתונים של החברה ו\או העברת מידע אודותיך או מידע שנמסר על ידך לצד ג' כלשהו, וזאת ככל שנקטו בצעדים סבירים ומקובלים למניעת פעולות שכאלה.
              </p>
              <p>

                החברה לא תמכור או תעביר לאחרים מידע אישי שיתקבל אודותיך, ללא הסכמתך המוקדמת, למעט במקרים הבאים: (א) חלה חובה על מסירת המידע בהתאם להוראות הדין או החלטה שיפוטית; (ב) ישנו סכסוך בינך ובין החברה בקשר עם המידע האמור; (ג) כאשר השימוש באתר או באפליקציה נעשה בניגוד לאמור בתנאי השימוש ו/או בניגוד להוראות כל דין; (ד) מועבר מידע שאינו מזהה אותך באופן אישי, שעיקרו הרגלי גלישה ומידע סטטיסטי (ה) לרוכש פוטנציאלי במסגרת מכירת החברה או כלל נכסיה;
              </p>
              <p>
                החברה לא תיחשב כמפרת התחייבות לפרטיות או פוגעת בפרטיות משתמש בשל כל מידע, כהגדרתו בחוק המחשבים תשנ"ה-1995 ,שיהיה בו כדי לזהות משתמש או להתחקות אחריו על ידי אחר, שנובע מהשימוש באמצעי תקשורת אלקטרוניים ככלל ותקשורת מחשבים בפרט.
              </p>
              <p>
                בכל מקרה שבו תרצה לעיין במידע אשר קיים אצלנו אודותיך ולעדכנו או לחלופין שנמחוק מידע אישי אודותיך או את כל הפרטים שנאספו אודותיך, תהיה לך הזכות לפנות אלינו באמצעות מייל _______________  . בתוך 3 ימי עסקים ימחקו הפרטים שביקשת, למעט הפרטים שחיוני שנשמור. דע כי מחיקה כאמור עלולה לפגוע בחלק מהשירותים שתקבל באמצעות האתר והאפליקציה.

              </p>
              <p>

                לכל שאלה או הבהרה נשמח אם תיצור קשר לכתובת המייל support@nineplus.life
              </p>
            </div>

        </div>
    </div>
</section>
