<section>
    <div class="jumbotron jumbotron-fluid" *ngIf="data">
        <div class="bgimage" [style.background-image]="'url(' + data.imageUrl + ')'"></div>
        <div class="container">
            <h1>{{data.title}}</h1>
        </div>
    </div>
</section>
<section id="post-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div [innerHtml]="html"></div>
            </div>
        </div>
    </div>
</section>