import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PostsComponent } from './components/posts/posts.component';
import { PostComponent } from './components/posts/post/post.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { TrialPlanComponent } from './components/trial-plan/trial-plan.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { BasicPlanComponent } from './components/basic-plan/basic-plan.component';
import { FullPlanComponent } from './components/full-plan/full-plan.component';
import { SuccessComponent } from './components/payments/success/success.component';
import { FailComponent } from './components/payments/fail/fail.component';
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import {QuestionnaireComponent} from './components/questionnaire/questionnaire.component';
import {DiamondsComponent} from './components/diamonds/diamonds.component';
import {SignupComponent} from './components/signup/signup.component';
import {PrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';
import {EulaComponent} from "./components/eula/eula.component";
import {LotteryTermsComponent} from "./components/lottery-terms/lottery-terms.component";


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'posts', component: PostsComponent },
  { path: 'posts/:id', component: PostComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'plans/trial', component: TrialPlanComponent },
  { path: 'plans/basic', component: BasicPlanComponent },
  { path: 'plans/full', component: FullPlanComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'payments/success', component: SuccessComponent },
  { path: 'payments/fail', component: FailComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'questionnaire', component: QuestionnaireComponent },
  { path: 'diamonds', component: DiamondsComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'lottery-terms', component: LotteryTermsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
