<div class="container">
    <div class="row">
        <div class="col-12">
            <div *ngFor="let post of posts">
                <div class="row post" (click)="goToPost(post.id)">
                    <div class="col-sm-5 postimage" [style.background-image]="'url(' + post.imageUrl + ')'">
                    </div>
                    <div class="col-sm-7 my-auto posttext">
                        <h3>{{post.title}}</h3>
                        <p>
                            {{post.readMoreText}}...
                            <a [routerLink]="['/posts', post.id]">
                                {{ 'Posts.ReadMore' | translate }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>