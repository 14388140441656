import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NewUser } from '../models/new-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public register(data: NewUser): Observable<any> {
    const url = `${environment.apiUrl}/public/register`;

    return this.http.post(url, data);
  }

  public updateQuestionnaire(token: string, data: any): Observable<any> {
    const url = `${environment.apiUrl}/users/CompleteQuestionnaire`;

    return this.http.post(url, data, {
      params: {
        token
      }
    });
  }
}
