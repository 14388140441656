import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCheck, faTimes, faAngleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  private _planTranslate: string;

  @Input()
  planId: string;

  @Input()
  checkList: number[];

  @Input()
  isBestPlan: boolean = false;

  @Input()
  set planTranslate(value: string) {
    this._planTranslate = value;
  }

  get planTranslate(): string {
    return this._planTranslate
      ? this._planTranslate
      : this.planId[0].toUpperCase() + this.planId.substring(1);
  }

  faCheck = faCheck;
  faTimes = faTimes;
  faAngleUp = faAngleUp;
  faCheckStyle = { 'stroke': 'green', 'color': 'green' }
  faTimesStyle = { 'stroke': 'red', 'color': 'red', 'opacity': 0 }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public planClicked(): void {
    this.router.navigate(['/plans/basic' /* + this.planId*/])
  }
}
