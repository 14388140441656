import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
//import { HeaderService } from '../../services/ui-services/header.service';
//import { SeoService } from '../../services/ui-services/seo.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit, OnDestroy {

  transparent = false;

  constructor(/*private headerService: HeaderService, private seo: SeoService, private element: ElementRef*/) {
    //this.seo.setTagsFromJson(this.element.nativeElement.tagName);
  }

  ngOnInit() {
    //this.headerService.sendData(this.transparent);
  }

  ngOnDestroy() {
    //this.headerService.clearData();
    //this.seo.removeTags();
  }
}
