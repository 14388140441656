<section id="top" class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1>{{ 'Plans.Trial.Title' | translate}}</h1>
            <p class="price">
                {{ 'Plans.Trial.Price.0' | translate }},
                {{ 'Plans.Trial.Price.1' | translate | lowercase }}
            </p>
            <p>{{ 'Plans.Trial.Page.Text' | translate}}</p>
            <p>
                {{ 'Plans.Trial.Page.Remarks.0.0' | translate}}
                <a routerLink="/plans/full">{{ 'Plans.Trial.Page.Remarks.0.1' | translate}}</a>
                {{ 'Plans.Trial.Page.Remarks.0.2' | translate}}
                <a routerLink="/plans/basic">{{ 'Plans.Trial.Page.Remarks.0.3' | translate}}</a>.
            </p>
            <p>{{ 'Plans.Trial.Page.Remarks.1' | translate}}</p>
        </div>
    </div>
</section>
<section class="container pb-5">
    <div class="row flex-row-reverse">
        <div class="col-md-6">
            <div id="image-bg">
                <div style="background-image: url(/assets/images/plan-trial.png);"></div>
            </div>
        </div>
        <div class="col-md-6">
            <app-registration-form></app-registration-form>
        </div>
    </div>
</section>
