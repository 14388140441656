import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewUser } from 'src/app/models/new-user.model';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService as UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  @Input()
  public subscriptionPlanId?: number;

  private minPregnancyWeek = 4;
  private maxPregnancyWeek = 41;
  private subscription: Subscription;

  public model = new NewUser();
  public error: string;

  constructor(
    private usersService: UsersService,
    private paymentsService: PaymentService,
    private router: Router  ) { }

  ngOnInit(): void {
  }

  public get isLoading(): boolean {
    return this.subscription && !this.subscription.closed;
  }

  public submit() {
    this.subscription = this.usersService
      .register(this.model)
      .subscribe(
        () => {
          if (this.subscriptionPlanId) {
            this.paymentsService.pay(this.subscriptionPlanId);
          } else {
            this.router.navigate(['/thank-you']);
          }
        },
        err => this.error = this.getErrorMessage(err)
      );
  }

  private getErrorMessage(err: any): string {
    if (err?.status) {
      switch (err.status) {
        case 409: return 'Register.Form.Errors.Conflict';
      }
    }
    return 'Register.Form.Errors.Default'
  }

  public fixPregnancyWeek() {
    this.model.pregnancyWeek = this.model.pregnancyWeek == null
      ? null
      : (this.model.pregnancyWeek < this.minPregnancyWeek
        ? this.minPregnancyWeek
        : (this.model.pregnancyWeek > this.maxPregnancyWeek
          ? this.maxPregnancyWeek
          : this.model.pregnancyWeek))
  }
}
