<app-google-analytics-gtag></app-google-analytics-gtag>

<div *ngIf="!isLoaded"></div>

<nav class="navbar navbar-default navbar-light navbar-expand-lg fixed-top" *ngIf="isLoaded">
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/images/logo.png" alt="NinePlus.Life" height="56px" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!--
        <li class="nav-item">
          <a class="nav-link" routerLink="/plans/trial">{{ 'NavigationLinks.Trial' | translate }}</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" href="/#plans" type="button" id="plansButton">
            {{ 'NavigationLinks.Plans' | translate }}
          </a>
        </li>
        <!--
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" type="button" id="plansButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ 'NavigationLinks.Plans' | translate }}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="plansButton">
            <a class="dropdown-item" routerLink="/plans/basic">{{ 'Plans.Basic.Title' | translate }}</a>
            <a class="dropdown-item" routerLink="/plans/full">{{ 'Plans.Full.Title' | translate }}</a>
          </div>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" routerLink="/posts">{{ 'NavigationLinks.PostPage' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact-us">{{ 'NavigationLinks.ContactUs' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="main-container" *ngIf="isLoaded">
  <router-outlet></router-outlet>
  <footer>
    <div class="container">
      <div class="row">
        <!--
        <div class="col-md-3">
          <h4>{{ 'Footer.Languages' | translate }}</h4>
          <ul class="languages">
            <li *ngFor="let language of translate.getLangs()">
              <a href=""
                (click)="$event.preventDefault(); switchLang(language)">{{ 'Languages.' + language | translate }}</a>
            </li>
          </ul>
        </div>-->
        <div class="col-md-4">
          <h4>{{ 'Footer.UsefulLinks' | translate }}</h4>
          <ul>
            <!--
            <li>
              <a routerLink="/">{{ 'NavigationLinks.Home' | translate }}</a>
            </li>-->
            <!--
            <li>
              <a routerLink="/plans/trial">{{ 'NavigationLinks.Trial' | translate }}</a>
            </li>-->
            <li>
              <a routerLink="/posts">{{ 'NavigationLinks.PostPage' | translate }}</a>
            </li>
            <li>
              <a routerLink="/contact-us">{{ 'NavigationLinks.ContactUs' | translate }}</a>
            </li>
            <!--
            <li>
              <a href="mailto:support@nineplus.life">support@nineplus.life</a>
            </li>-->
          </ul>
        </div>
        <div class="col-md-4">
          <h4></h4>
          <ul>
            <li>
              <a class="nav-link" href="/#plans" type="button">
                {{ 'NavigationLinks.Plans' | translate }}
              </a>
            </li>
          </ul>
          <!--
          <h4>{{ 'Footer.Plans' | translate }}</h4>
          <ul>
            <li>
              <a routerLink="/plans/basic">{{ 'Plans.Basic.Title' | translate }}</a>
            </li>
            <li>
              <a routerLink="/plans/full">{{ 'Plans.Full.Title' | translate }}</a>
            </li>
          </ul>-->
        </div>
        <div class="col-md-4">
          <h4>&nbsp;</h4>
          <ul>
            <li>
              <a routerLink="/terms-of-use">{{ 'NavigationLinks.TermsOfUse' | translate }}</a>
            </li>
            <li>
              <a routerLink="/privacy-policy">מדיניות הפרטיות</a>
            </li>
            <li>
              <a routerLink="/eula">EULA</a>
            </li>
            <li>
              <a routerLink="/lottery-terms">תקנון הגרלה  </a>
            </li>
          </ul>
        </div>
        <div class="col-md-3"></div>
      </div>
      <!--
      <div class="row">
        <div class="col-12">
          <app-try-us [linksOnly]="true"></app-try-us>
        </div>
      </div>
      -->
      <div class="footer-bottom" dir="ltr">
        <small>&copy; Copyright {{currentYear}}, NinePlus.Life from MamaSupport LTD</small>
      </div>
    </div>
  </footer>
</div>
