<div class="my-3 plan" [class.best-plan]="isBestPlan">
    <div class="inner-plan row">
        <div class="col-12">
            <div class="row text-center top" (click)="planClicked();">
                <div class="col-12">
                    <h3>{{ 'Plans.' + planTranslate + '.Title' | translate}}</h3>
                    <p>{{ 'Plans.' + planTranslate + '.Subtitle' | translate}}</p>
                </div>
            </div>
            <div class="row features" >
                <div class="col-12" >
                    <div class="toggle-container"><!-- *ngIf="planId === 'basic'" -->
                        <div [id]="planId" class="collapse">
                            <div *ngFor="let i of checkList" class="feature">
                                <div class="feature-icon">
                                  <!--
                                  <fa-icon [icon]="checkList[i] ? faCheck : faTimes"
                                    [styles]="checkList[i] ? faCheckStyle : faTimesStyle"></fa-icon>-->
                                  <fa-icon [icon]="faCheck" [styles]="faCheckStyle"></fa-icon>
                                </div>
                                <div class="feature-text" *ngIf="'Plans.' + planTranslate + '.Features.' + i">{{ 'Plans.' + planTranslate + '.Features.' + i | translate}}</div>
                            </div>
                            <div>
                              <a routerLink="/plans/basic">לקרוא עוד...</a>
                            </div>
                        </div>
                        <button class="btn w-100 px-0 arrow-up" type="button" data-toggle="collapse"
                            [attr.data-target]="'#' + planId" aria-expanded="false">
                            <span>{{ 'Plans.WhatIncluded' | translate }}</span>
                            <fa-icon [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                    <div class="features-placeholder" [class]="planId + '-placeholder'">
                      <a href="https://lp.nineplus.life/freetips/" target="_blank"></a>
                    </div>
                </div>
            </div>
            <div class="row text-center bottom p-2">
                <div class="col-12">
                    <a href="https://lp.nineplus.life/freetips/" target="_blank">{{ 'Plans.' + planTranslate + '.Price.' + 0 | translate}}</a>
                </div>
              <!--
              <div class="col-12" *ngIf="planId !== 'basic'">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>-->
            </div>
        </div>
    </div>
</div>
