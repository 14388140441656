import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-diamonds',
  templateUrl: './diamonds.component.html',
  styleUrls: ['./diamonds.component.scss']
})
export class DiamondsComponent implements OnInit, OnDestroy {

  constructor(){

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

}

