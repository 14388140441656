import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public title = 'website';
  public currentYear = new Date().getFullYear();
  public isLoaded = false;

  private _defaultLang = 'he'; // 'en'

  public constructor(public translate: TranslateService) {
    translate.onLangChange.subscribe((event) => {
      this.fixDirection(event.lang);
      //TODO: disabled : localStorage.setItem('lang', translate.currentLang);
    });
  }
  ngAfterViewInit(): void {
    this.isLoaded = true;
  }
  ngOnInit(): void {
    //let langOrDefault = (l: string, d: string) => this.translate.langs.includes(l) ? l : d;

    let lang = this._defaultLang;

    /*
    try {
      //TODO: disabled :
      const storedLang = localStorage.getItem('lang');
      if(storedLang){
        lang = storedLang;
      }
      //lang = langOrDefault(lang, this.translate.getBrowserCultureLang());
      //lang = langOrDefault(lang, this._defaultLang);
    } catch {
      //lang = this._defaultLang;
    }*/

    this.translate.use(lang);
    this.fixDirection(lang);
  }

  private fixDirection(lang: any) {
    let dir = lang == 'he' ? 'rtl' : 'ltr';

    try {
      document.getElementsByTagName("html")[0].setAttribute('lang', lang);
      document.getElementsByTagName("body")[0].setAttribute('dir', dir);
    } catch { }
  }

  public switchLang(lang: string) {
    this.translate.use(lang);
  }
}
