import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import {Observable} from 'rxjs/Observable';
import {ContactUsModel} from 'src/app/models/contact-us.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class SendMailService {

  constructor(private http: HttpClient) { }

  sendContactUsMessage(data: ContactUsModel){
    return this.http.post<ContactUsModel>('/api/sendContactUs.php', data);
  }
}
