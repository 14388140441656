import { Component, OnDestroy, OnInit } from '@angular/core';
import { PostsService } from 'src/app/services/posts.service';
import { Post } from 'src/app/models/post.model';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnDestroy, OnInit {
  private subscription: Subscription;

  posts: Post[];


  public constructor(
    private postsService: PostsService, 
    private translate: TranslateService,
    private router: Router) {
    this.translate.onLangChange.subscribe(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.loadPosts();
    });
  }

  public ngOnInit(): void {
    this.loadPosts();
  }
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public goToPost(postId: string): void {
    this.router.navigate(['/posts', postId]);
  }

  private loadPosts(): void {
    this.subscription = this.postsService.getPosts().subscribe(
      (data) => this.posts = data
    );
  }
}
