import {Component, ViewEncapsulation} from '@angular/core';
import { faCheck, faTimes, faAngleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-basic-plan',
  templateUrl: './basic-plan.component.html',
  styleUrls: ['./basic-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicPlanComponent {
  faCheck = faCheck;
  faCheckStyle = { stroke: 'green', color: 'green' };

  public subscriptionPlanId = 3;

}
