import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor() { }

  public pay(subscriptionPlanId): void {
    let url = `${environment.apiUrl}/payments/pay?subscriptionPlanId=${subscriptionPlanId}`

    window.location.href = url;
  }
}
