import { Component, OnInit, TemplateRef, OnDestroy, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SendMailService} from 'src/app//services/send-mail.service';
import {ContactUsModel} from 'src/app/models/contact-us.model';
import {UserService} from '../../services/users.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  public model = {
    FullName: null,
    Age: null,
    Week: null,
    PregnancyNumber: null,
    ChildrenNumber: null,
    Residence: null,
    Date: null
    // public int AreaId { get; set; }
    // public string MedicalFund { get; set; }
    // public string HealthStatus { get; set; }
    // public string PersonType { get; set; }
    // public int[] LanguageIds { get; set; }
  };

  public isRequestProcessing = false;

  public token: string;

  public formError: string = null;

  public success = false;

  private _routeParamsSubscription: any = null;

  constructor( private route: ActivatedRoute, private userService: UserService){

  }

  ngOnInit() {
    this._routeParamsSubscription = this.route.queryParams.subscribe(params => {
      console.log('params', params);
      if (params['token']) {
        this.token = params['token'];
      }
    });
  }

  ngOnDestroy() {
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }

  onSubmitForm() {
    this.formError = null;
    this.isRequestProcessing = true;

    this.userService.updateQuestionnaire(this.token, this.model)
      .subscribe(
        (data) => {
          console.log(data);
          // formDirective.resetForm();
          // this.contactUsForm.reset();
          // this.isSuccessResponse = true;
          // this.modalRef = this.modalService.show(successModal, this.config);
          this.isRequestProcessing = false;
          this.success = true;
        },
        error => {
          // this.isSuccessResponse = false;
          console.log(error);
          // this.modalRef = this.modalService.show(errorModal, this.config);
          this.isRequestProcessing = false;

          this.formError = 'Internal error';

          if(error && error.error){
            if(error.error.ExceptionMessage){
              this.formError = error.error.ExceptionMessage;
            } else  if(error.error.Message){
              this.formError = error.error.Message;
            }
          }
        }
      );
  }
}

