import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from 'src/app/services/posts.service';
import { TranslateService } from '@ngx-translate/core';
import { Post } from 'src/app/models/post.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostComponent implements OnDestroy {
  private subscription: Subscription;

  data: Post;
  html: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private postsService: PostsService,
    private translate: TranslateService
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.loadPost(params.get('id'));
    });
  }


  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private loadPost(id: string) {
    let lang = this.translate.currentLang;

    this.subscription = this.postsService.getPostHtml(id).subscribe(data => this.html = data);
    this.postsService.getPost(id).then(data => this.data = data);
  }
}
