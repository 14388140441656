import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/home/home.component';
import { TryUsComponent } from './components/commons/try-us/try-us.component';
import { PartnersComponent } from './components/commons/partners/partners.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PostsComponent } from './components/posts/posts.component';
import { PostComponent } from './components/posts/post/post.component';
import { TranslateBrowserLoader } from './translate-browser-loader';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { RegistrationFormComponent } from './components/forms/registration-form/registration-form.component';
import { TrialPlanComponent } from './components/trial-plan/trial-plan.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { BasicPlanComponent } from './components/basic-plan/basic-plan.component';
import { FullPlanComponent } from './components/full-plan/full-plan.component';
import { AlreadyUserComponent } from './components/commons/already-user/already-user.component';
import { SuccessComponent } from './components/payments/success/success.component';
import { FailComponent } from './components/payments/fail/fail.component';
import { GoogleAnalyticsGTagComponent } from './google-analytics-gtag/google-analytics-gtag.component';
import { PlanComponent } from './components/home/plan/plan.component';
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import {SendMailService} from "./services/send-mail.service";
import {QuestionnaireComponent} from "./components/questionnaire/questionnaire.component";
import {DiamondsComponent} from "./components/diamonds/diamonds.component";
import {SignupComponent} from "./components/signup/signup.component";
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {EulaComponent} from "./components/eula/eula.component";
import {LotteryTermsComponent} from "./components/lottery-terms/lottery-terms.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TryUsComponent,
    PartnersComponent,
    PostsComponent,
    PostComponent,
    TermsOfUseComponent,
    RegistrationFormComponent,
    TrialPlanComponent,
    ThankYouComponent,
    BasicPlanComponent,
    FullPlanComponent,
    AlreadyUserComponent,
    SuccessComponent,
    FailComponent,
    GoogleAnalyticsGTagComponent,
    PlanComponent,
    ContactUsComponent,
    QuestionnaireComponent,
    DiamondsComponent,
    SignupComponent,
    PrivacyPolicyComponent,
    EulaComponent,
    LotteryTermsComponent
  ],
  imports: [
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: transferLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    SendMailService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    const langs = ['en', 'he'];

    translate.addLangs(langs);
    translate.use('en');
    translate.defaultLang = 'en';
  }
}

export function transferLoaderFactory(httpClient: HttpClient, transferState: TransferState) {
  return new TranslateBrowserLoader(transferState, httpClient);
}
